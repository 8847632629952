import { useEffect, useState } from 'react';

// Hook
function useStickyScroll(stickyPos: number, stickyElem: HTMLElement | null) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [currStickyPos, setCurrStickyPos] = useState<{
    position: 'relative' | 'fixed';
    top: string;
    offset: number;
    objectHeight: number;
  }>({
    position: 'relative',
    top: 'initial',
    offset: 0,
    objectHeight: 0,
  });

  useEffect(() => {
    const handleScroll = () => {
      /* Check if the current Y offset
      is greater than the position of
      the element */
      if (!stickyElem) return;

      const elementHeight = stickyElem.getBoundingClientRect().height;
      if (window.scrollY > elementHeight - stickyPos) {
        setCurrStickyPos({
          position: 'fixed',
          top: `${-elementHeight + stickyPos}px`,
          offset: window.scrollY,
          objectHeight: elementHeight,
        });
      } else {
        setCurrStickyPos({
          position: 'relative',
          top: 'initial',
          offset: window.scrollY,
          objectHeight: elementHeight,
        });
      }
    };

    // Add event listener
    window.addEventListener('scroll', handleScroll);

    // Call handler right away so state gets updated with initial window size
    handleScroll();
    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleScroll);
  }, [stickyElem, stickyPos]); // Empty array ensures that effect is only run on mount

  return currStickyPos;
}

export default useStickyScroll;
