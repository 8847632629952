import { FC } from 'react';

// MUI
import { Box } from '@mui/material';
import { Props } from '../ifaces';

const MainLayout: FC<Props> = ({ children }) => {
  return (
    <Box
      id="page-head"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: 1,
        // overflowY: 'auto',
        justifyContent: 'center',
      }}
    >
      {/* <Stack
        maxWidth={'lg'}
        sx={{
          position: 'relative',
          flex: 1,
        }}
      >
        {children}
      </Stack> */}
      {children}
    </Box>
  );
};

export default MainLayout;
