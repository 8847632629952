import { Stack } from '@mui/material';
import AITravelAssistant from './AITravelAssistant';
import FixedNavigationBar from '../../../components/FixedNavigationBar';

const TripPlanerPage = () => {
  return (
    <Stack sx={{ flex: 1, background: 'rgb(244, 245, 251)' }}>
      <FixedNavigationBar lightVersion />
      <AITravelAssistant />
    </Stack>
  );
};

export default TripPlanerPage;
