import { Button, ButtonProps } from '@mui/material';
import { FC, useContext } from 'react';
import { UICtx } from '../UIProvider';

const SignInButton: FC<ButtonProps> = props => {
  const { darkMode, isMobile } = useContext(UICtx);

  return (
    <Button
      {...props}
      sx={{
        // background: darkMode ? 'rgb(6, 3, 24)' : 'transparent',
        color: darkMode ? '#888' : '#0d0c22',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textTransform: 'none',
        borderRadius: '8px',
        fontWeight: 700,
        py: 0,
        border: darkMode ? '1px solid #888' : '1px solid #0d0c22',
        '&:hover': {
          border: darkMode ? '1px solid white' : '1px solid #0d0c22',
          //   background: darkMode ? 'transparent' : '#0d0c22',
          color: darkMode ? 'white' : '#0d0c22',
        },
        fontSize: '0.7rem',
        width: isMobile ? '50px' : '64px',
        height: '36px',
        ...props.sx,
      }}
      onClick={props.onClick}
    >
      Login
    </Button>
  );
};

export default SignInButton;
