import { ButtonProps, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FC } from 'react';

interface PulseDownButtonProps {
  light?: boolean;
}

const PulseDownButton: FC<ButtonProps & PulseDownButtonProps> = props => {
  return (
    <IconButton
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99,
        border: 'none',
        backgroundSize: '18px',
        cursor: 'pointer',
        outline: 'none',
        color: 'black',
        position: 'relative',
        opacity: 0.4,
        ':after': {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          borderRadius: '50%',
          background: props.light ? '#DEDEDE' : '#444',
          scale: 2,
          zIndex: -1,
        },
        ':before': {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          borderRadius: '50%',
          animation: 'pulse 1.2s ease infinite',
          transform: 'scale(2)',
          background: props.light ? '#DEDEDE' : '#333',
          scale: 2,
          zIndex: -1,
          '@keyframes pulse': {
            '0%': {
              transform: 'scale(1, 1)',
            },
            '50%': {
              opacity: 0.3,
            },
            '100%': {
              transform: 'scale(1.5)',
              opacity: 0,
            },
          },
        },
        ...props.sx,
      }}
      //   disableRipple
      onClick={props.onClick}
    >
      <KeyboardArrowDownIcon
        sx={{
          color: props.light ? 'white' : 'black',
          fontSize: '2rem',
          '&:hover': {
            color: props.light ? '#444' : 'white',
          },
        }}
      />
    </IconButton>
  );
};

export default PulseDownButton;
