import { Stack } from '@mui/material';
import NavigationBar from '../../components/NavigationBar';

const AdminPage = () => {
  return (
    <Stack
      sx={{
        flex: 1,
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      }}
    >
      <NavigationBar />
    </Stack>
  );
};

export default AdminPage;
