import { useContext, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

// Profile Pictures
import Avatar0 from '../../../assets/avatar_0.jpg';
import Avatar1 from '../../../assets/avatar_1.jpg';
import Avatar2 from '../../../assets/avatar_2.jpg';
import Avatar4 from '../../../assets/avatar_4.jpg';
import Avatar5 from '../../../assets/avatar_5.jpg';
import Avatar6 from '../../../assets/avatar_6.jpg';
import Avatar7 from '../../../assets/avatar_7.jpg';
import Avatar8 from '../../../assets/avatar_8.jpg';
import Avatar9 from '../../../assets/avatar_9.jpg';
import Avatar10 from '../../../assets/avatar_10.jpg';
import Avatar12 from '../../../assets/avatar_12.jpg';
import Avatar13 from '../../../assets/avatar_13.jpg';
import Avatar14 from '../../../assets/avatar_14.jpg';
import Avatar15 from '../../../assets/avatar_15.jpg';
import Avatar16 from '../../../assets/avatar_16.jpg';
import Avatar17 from '../../../assets/avatar_17.jpg';

import './AvatarImage.module.css';
import { UICtx } from '../../../UIProvider';

// https://codepen.io/ixkaito/pen/NWgVyMz
const AvatarImage = () => {
  const { isMobile } = useContext(UICtx);
  const [currentImage, setCurrentImage] = useState(0);
  const [glitchAnimationOn, setGlitchAnimationOn] = useState(true);

  // Change the image every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setGlitchAnimationOn(true);
      setTimeout(() => {
        setCurrentImage(Math.floor(Math.random() * 17));
        setTimeout(() => {
          setGlitchAnimationOn(false);
          // Random number between 400 and 1500
          let randomTime = Math.floor(Math.random() * 1100) + 400;
          setTimeout(() => {
            // Random number between 100 and 300
            let randomDuration = Math.floor(Math.random() * 200) + 100;
            setGlitchAnimationOn(true);
            setTimeout(() => {
              setGlitchAnimationOn(false);

              randomTime = Math.floor(Math.random() * 1000) + 400;
              setTimeout(() => {
                // Random number between 100 and 300
                randomDuration = Math.floor(Math.random() * 150) + 50;
                setGlitchAnimationOn(true);
                setTimeout(() => {
                  setGlitchAnimationOn(false);
                }, randomDuration);
              }, randomTime);
            }, randomDuration);
          }, randomTime);
        }, 700);
      }, 200);
      // Set a random image
    }, 5000);
    return () => clearInterval(interval);
  }, [currentImage]);

  const getCurrentImage = () => {
    switch (currentImage) {
      case 0:
        return Avatar0;
      case 1:
        return Avatar1;
      case 2:
        return Avatar2;
      case 4:
        return Avatar4;
      case 5:
        return Avatar5;
      case 6:
        return Avatar6;
      case 7:
        return Avatar7;
      case 8:
        return Avatar8;
      case 9:
        return Avatar9;
      case 10:
        return Avatar10;
      case 12:
        return Avatar12;
      case 13:
        return Avatar13;
      case 14:
        return Avatar14;
      case 15:
        return Avatar15;
      case 16:
        return Avatar16;
      case 17:
        return Avatar17;
      default:
        return Avatar0;
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: isMobile ? '200px' : '350px',
        height: isMobile ? '200px' : '350px',
        overflow: 'hidden',
        borderRadius: '50%',
      }}
    >
      <Stack
        className="glitch"
        sx={{
          // zIndex: 10,
          backgroundImage: `url(${getCurrentImage()})`,
          backgroundSize: 'cover',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          // boxShadow: '0 0 0 5px rgba(200, 200, 255, 0.1)',
          overflow: 'hidden',

          ':before': !glitchAnimationOn
            ? undefined
            : {
                background: 'inherit',
                backgroundSize: 'cover',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                animation: 'glitch-before 3s linear infinite alternate both',
                content: '""',
                '@keyframes glitch-before': {
                  '0%': {
                    'clip-path':
                      'polygon(0% 76.1507235335%, 100% 76.1507235335%, 100% 78.4951617353%, 0% 78.4951617353%)',
                    transform: 'translate(3.4912725201%, -0.3994039855%)',
                  },
                  '2%': {
                    'clip-path':
                      'polygon(0% 66.5127118519%, 100% 66.5127118519%, 100% 73.2011536272%, 0% 73.2011536272%)',
                    transform: 'translate(-7.5702531776%, -0.3574302777%)',
                  },
                  '4%': {
                    'clip-path':
                      'polygon(0% 77.9021107747%, 100% 77.9021107747%, 100% 81.5233678781%, 0% 81.5233678781%)',
                    transform: 'translate(-4.1822802272%, -0.4523196837%)',
                  },
                  '6%': {
                    'clip-path':
                      'polygon(0% 44.4610295167%, 100% 44.4610295167%, 100% 54.4011631985%, 0% 54.4011631985%)',
                    transform: 'translate(-5.340847651%, -0.3178414132%)',
                  },
                  '8%': {
                    'clip-path':
                      'polygon(0% 18.9085993216%, 100% 18.9085993216%, 100% 23.904200708%, 0% 23.904200708%)',
                    transform: 'translate(0.5396190905%, 0.4388898224%)',
                  },
                  '10%': {
                    'clip-path': 'polygon(0% 3.6974942982%, 100% 3.6974942982%, 100% 12.516626184%, 0% 12.516626184%)',
                    transform: 'translate(3.4522979544%, 0.2526861028%)',
                  },
                  '12%': {
                    'clip-path':
                      'polygon(0% 14.5137051721%, 100% 14.5137051721%, 100% 17.8257153309%, 0% 17.8257153309%)',
                    transform: 'translate(-3.4637544146%, -0.1730783754%)',
                  },
                  '14%': {
                    'clip-path':
                      'polygon(0% 49.8827210672%, 100% 49.8827210672%, 100% 56.5261945087%, 0% 56.5261945087%)',
                    transform: 'translate(0.0922215887%, -0.2633796793%)',
                  },
                  '16%': {
                    'clip-path':
                      'polygon(0% 61.8414946015%, 100% 61.8414946015%, 100% 66.2420667312%, 0% 66.2420667312%)',
                    transform: 'translate(6.5009272647%, -0.0086524536%)',
                  },
                  '18%': {
                    'clip-path': 'polygon(0% 76.49912558%, 100% 76.49912558%, 100% 77.8960235106%, 0% 77.8960235106%)',
                    transform: 'translate(-5.2735863231%, -0.1853393985%)',
                  },
                  '20%': {},
                  '100%': {
                    'clip-path': 'none',
                    transform: 'none',
                  },
                },
              },

          ':after': !glitchAnimationOn
            ? undefined
            : {
                background: 'inherit',
                backgroundSize: 'cover',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                animation: 'glitch-after 3s linear infinite alternate both',
                content: '""',
                '@keyframes glitch-after': {
                  '0%': {
                    'clip-path':
                      'polygon(0% 37.6612235437%, 100% 37.6612235437%, 100% 41.5948314565%, 0% 41.5948314565%)',
                    transform: 'translate(-2.6903354179%, -0.1603207209%)',
                  },
                  '2%': {
                    'clip-path':
                      'polygon(0% 19.8060346258%, 100% 19.8060346258%, 100% 21.1245720571%, 0% 21.1245720571%)',
                    transform: 'translate(1.1914458445%, -0.3384672612%)',
                  },
                  '4%': {
                    'clip-path':
                      'polygon(0% 50.469414014%, 100% 50.469414014%, 100% 54.9664939764%, 0% 54.9664939764%)',
                    transform: 'translate(-3.7604719465%, -0.1601265112%)',
                  },
                  '6%': {
                    'clip-path':
                      'polygon(0% 84.139810203%, 100% 84.139810203%, 100% 90.2402521043%, 0% 90.2402521043%)',
                    transform: 'translate(-0.7069720407%, -0.4371607352%)',
                  },
                  '8%': {
                    'clip-path':
                      'polygon(0% 52.4865919415%, 100% 52.4865919415%, 100% 57.3671195892%, 0% 57.3671195892%)',
                    transform: 'translate(2.9908218752%, -0.2430507166%)',
                  },
                  '10%': {
                    'clip-path':
                      'polygon(0% 28.3111874919%, 100% 28.3111874919%, 100% 33.8576482474%, 0% 33.8576482474%)',
                    transform: 'translate(-1.2763638924%, 0.2047025406%)',
                  },
                  '12%': {
                    'clip-path':
                      'polygon(0% 20.0078727301%, 100% 20.0078727301%, 100% 29.0133058021%, 0% 29.0133058021%)',
                    transform: 'translate(1.3423672777%, -0.2935760011%)',
                  },
                  '14%': {
                    'clip-path':
                      'polygon(0% 72.9261215274%, 100% 72.9261215274%, 100% 78.2687954856%, 0% 78.2687954856%)',
                    transform: 'translate(1.5831132959%, -0.4431906285%)',
                  },
                  '16%': {
                    'clip-path':
                      'polygon(0% 84.8351922401%, 100% 84.8351922401%, 100% 91.4392799979%, 0% 91.4392799979%)',
                    transform: 'translate(2.463393638%, -0.3492663584%)',
                  },
                  '18%': {
                    'clip-path': 'polygon(0% 76.49912558%, 100% 76.49912558%, 100% 77.8960235106%, 0% 77.8960235106%)',
                    transform: 'translate(-2.2735863231%, -0.1853393985%)',
                  },
                  '20%': {},
                  '100%': {
                    'clip-path': 'none',
                    transform: 'none',
                  },
                },
              },
        }}
      >
        {glitchAnimationOn && (
          <>
            <Box
              className="channel r"
              sx={{
                position: 'absolute',
                background: 'inherit',
                backgroundSize: 'cover',
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                content: '""',
                borderRadius: '50%',
                overflow: 'hidden',
                // display: 'block',
                'mix-blend-mode': 'screen',
                ':before': {
                  backgroundSize: 'cover',
                  background: 'red',
                  content: '""',
                  display: 'block',
                  'mix-blend-mode': 'multiply',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  top: 0,
                },
                animation: 'rgb-shift-r 3s steps(1, jump-end) infinite alternate both',
                '@keyframes rgb-shift-r': {
                  '0%': {
                    transform: 'translate(-1.7662539612%, -0.2266890554%)',
                  },
                  '2%': {
                    transform: 'translate(-0.8851289519%, 0.3638866989%)',
                  },
                  '4%': {
                    transform: 'translate(0.387102609%, 0.0016692605%)',
                  },
                  '6%': {
                    transform: 'translate(1.6112804407%, -0.4858580358%)',
                  },
                  '8%': {
                    transform: 'translate(1.9660463805%, -0.3674109132%)',
                  },
                  '10%': {
                    transform: 'translate(0.9578053382%, 0.4615991612%)',
                  },
                  '11%': {
                    transform: 'translate(-1.0086777432%, -0.4636522829%)',
                  },
                  '12%': {
                    transform: 'translate(1.3275741987%, 0.3466212773%)',
                  },
                  '13%': {
                    transform: 'translate(-1.2142995633%, -0.1618808822%)',
                  },
                  '14%': {
                    transform: 'translate(1.7246897419%, 0.0539348373%)',
                  },
                  '15%': {},
                  '100%': {
                    transform: 'none',
                  },
                },
              }}
            />
            <Box
              className="channel g"
              sx={{
                position: 'absolute',
                background: 'inherit',
                backgroundSize: 'cover',
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                content: '""',
                borderRadius: '50%',
                overflow: 'hidden',
                // display: 'block',
                'mix-blend-mode': 'screen',
                ':before': {
                  backgroundSize: 'cover',
                  background: 'green',
                  content: '""',
                  display: 'block',
                  'mix-blend-mode': 'multiply',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  top: 0,
                },
                animation: 'rgb-shift-g 3s steps(1, jump-end) infinite alternate both',
                '@keyframes rgb-shift-g': {
                  '0%': {
                    transform: 'translate(-1.5793928723%, -0.0108408391%)',
                  },
                  '2%': {
                    transform: 'translate(1.3670080615%, -0.3823336715%)',
                  },
                  '4%': {
                    transform: 'translate(0.9645664484%, 0.0519595365%)',
                  },
                  '6%': {
                    transform: 'translate(-0.0682652977%, 0.3665572606%)',
                  },
                  '8%': {
                    transform: 'translate(-1.4704334595%, -0.0130346415%)',
                  },
                  '10%': {
                    transform: 'translate(0.1977366543%, 0.2432374127%)',
                  },
                  '12%': {
                    transform: 'translate(-0.9885056273%, 0.1089685434%)',
                  },
                  '14%': {
                    transform: 'translate(1.7797652087%, -0.3662161491%)',
                  },
                  '16%': {
                    transform: 'translate(0.4382750091%, -0.0968791428%)',
                  },
                  '18%': {
                    transform: 'translate(0.0931785084%, -0.2512015265%)',
                  },
                  '20%': {},
                  '100%': {
                    transform: 'none',
                  },
                },
              }}
            />
            <Box
              className="channel b"
              sx={{
                position: 'absolute',
                background: 'inherit',
                backgroundSize: 'cover',
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                content: '""',
                borderRadius: '50%',
                overflow: 'hidden',
                // display: 'block',
                'mix-blend-mode': 'screen',
                ':before': {
                  backgroundSize: 'cover',
                  background: 'blue',
                  content: '""',
                  display: 'block',
                  'mix-blend-mode': 'multiply',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  top: 0,
                },
                animation: 'rgb-shift-b 3s steps(1, jump-end) infinite alternate both',
                '@keyframes rgb-shift-b': {
                  '0%': {
                    transform: 'translate(-0.3391165261%, -0.0024555183%)',
                  },
                  '2%': {
                    transform: 'translate(-1.1893533935%, -0.3748462886%)',
                  },
                  '4%': {
                    transform: 'translate(0.8673070155%, -0.3613964669%)',
                  },
                  '6%': {
                    transform: 'translate(1.7196643259%, 0.0812707365%)',
                  },
                  '8%': {
                    transform: 'translate(0.2002172285%, 0.3286694047%)',
                  },
                  '10%': {
                    transform: 'translate(-1.5152059599%, 0.2152086768%)',
                  },
                  '12%': {
                    transform: 'translate(1.3241813121%, 0.172300197%)',
                  },
                  '14%': {
                    transform: 'translate(-1.3966611061%, 0.014225368%)',
                  },
                  '16%': {
                    transform: 'translate(-0.3363572075%, -0.1941105539%)',
                  },
                  '18%': {
                    transform: 'translate(1.9327010504%, 0.3547606986%)',
                  },
                  '20%': {},
                  '100%': {
                    transform: 'none',
                  },
                },
              }}
            />
          </>
        )}
      </Stack>
    </Box>
  );
};

export default AvatarImage;
