import axios from 'axios';
import { FC, useContext, useEffect, useState } from 'react';
import defaultAvatar from '../assets/avatar-default.png';
import { AuthCtx } from '../AuthProvider';

interface UserProfileImageProps {
  size?: number;
  imageStoragePath?: string;
}

const UserProfileImage: FC<UserProfileImageProps> = ({ size = 40, imageStoragePath }) => {
  const { user } = useContext(AuthCtx);

  const [userImageData, setUserImageData] = useState<string>();

  // const getImageURL = async (path: string) => {
  //   try {
  //     const downloadURL = await getDownloadURL(ref(storage, path));
  //     setImageURL(downloadURL);
  //   } catch (error) {
  //     return '';
  //   }
  // };

  // useEffect(() => {
  //   if (imageStoragePath) {
  //     getImageURL(imageStoragePath);
  //   }
  // }, [imageStoragePath]);

  useEffect(() => {
    if (user?.photoURL) {
      // Download the image using the user's photoURL
      axios.get(user.photoURL, { responseType: 'blob' }).then(response => {
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = () => {
          const base64data = reader.result;
          setUserImageData(base64data as string);
        };
      });
    }
  }, [user]);

  return (
    <div
      style={{
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <img
        height={`${size}`}
        width={`${size}`}
        style={{ borderRadius: `${size}px` }}
        src={userImageData || defaultAvatar}
        loading="lazy"
        alt={'Profile'}
      />
    </div>
  );
};

export default UserProfileImage;
