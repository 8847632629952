import { Stack } from '@mui/material';

////////////////////////////////////////////////////////////////////////
////                      REACT / REDUX / UTILS                     ////
////////////////////////////////////////////////////////////////////////

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

////////////////////////////////////////////////////////////////////////
////                         STYLES &  ASSETS                       ////
////////////////////////////////////////////////////////////////////////

const generateRandomRgbaColor = (): { r: number; g: number; b: number; a: number } => {
  const r = Math.floor(Math.random() * 70) + 1;
  const g = Math.floor(Math.random() * 70) + 1;
  const b = Math.floor(Math.random() * 70) + 1;
  return {
    r,
    g,
    b,
    a: 1,
  };
};

interface ParticlesEffectProps {
  mouseX?: number;
  mouseY?: number;
}

type SquareState = {
  n: number;
  x: number;
  y: number;
  mouseOver: boolean;
  isEven: boolean;
  strokeOpacity: number;
  strokeColor: {
    r: number;
    g: number;
    b: number;
  };
  fillOpacity: number;
  fillColor: {
    r: number;
    g: number;
    b: number;
  };
};

const ParticlesEffect: React.FC<ParticlesEffectProps> = ({ mouseX, mouseY }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [layout, setLayout] = useState({
    size: { width: 0, height: 0 },
    numberOfSquaresX: 1,
    numberOfSquaresY: 1,
  });

  const MAX_SQUARE_SIZE = 60;

  // const [squares, setSquares] = useState<{ x: number; y: number; bend: number }[]>([]);
  // const [polygons, setPolygons] = useState<{ x: number; y: number; bend: number }[][]>([[]]);
  // const { isMobile } = useContext(UICtx);

  const squares = useMemo(() => {
    const arr = [];
    for (let i = 0; i < layout.numberOfSquaresX; i++) {
      for (let j = 0; j < layout.numberOfSquaresY; j++) {
        arr.push({
          x: (layout.size.width / layout.numberOfSquaresX) * i,
          y: (layout.size.height / layout.numberOfSquaresY) * j,
          n: i * layout.numberOfSquaresY + j,
        });
      }
    }
    return arr;
  }, [layout]);

  const [squaresState, setSquaresState] = useState<SquareState[]>([]);
  const [currentTick, setCurrentTick] = useState(0);
  const [lastTick, setLastTick] = useState(-1);

  useLayoutEffect(() => {
    if (currentTick === lastTick) return;

    setSquaresState(previousSquareStates => {
      const w = layout.size.width;
      const h = layout.size.height;

      const newSquaresState: SquareState[] = squares.map(square => {
        const previousState = previousSquareStates.find(s => s.n === square.n);

        const squareState: SquareState = {
          n: square.n,
          x: square.x,
          y: square.y,
          mouseOver: previousState ? previousState.mouseOver : false,
          isEven: previousState ? previousState.isEven : false,
          strokeOpacity: previousState ? previousState.strokeOpacity : 0,
          strokeColor: previousState
            ? previousState.strokeColor
            : {
                r: 0,
                g: 0,
                b: 0,
              },
          fillOpacity: previousState ? previousState.fillOpacity : 0,
          fillColor: previousState
            ? previousState.fillColor
            : {
                r: 0,
                g: 0,
                b: 0,
              },
        };

        if (mouseX !== undefined && mouseY !== undefined) {
          // Adjust opacity based on distance from mouse
          const distanceFromMouse = Math.sqrt(Math.pow(mouseX - square.x, 2) + Math.pow(mouseY - square.y, 2));
          squareState.strokeOpacity = Math.max(0, 1 - distanceFromMouse / 350);
        }

        const postXGrid = square.x / (w / layout.numberOfSquaresX);
        const postYGrid = square.y / (h / layout.numberOfSquaresY);

        if (postYGrid % 2 === 0) {
          squareState.isEven = postXGrid % 2 === 0;
        } else {
          squareState.isEven = (postXGrid + 1) % 2 === 0;
        }

        // Calculate if mouse is inside the square
        const mouseOver =
          mouseX !== undefined &&
          mouseY !== undefined &&
          mouseX >= square.x &&
          mouseX <= square.x + w / layout.numberOfSquaresX &&
          mouseY >= square.y &&
          mouseY <= square.y + h / layout.numberOfSquaresY;

        // If mouse if inside the square
        squareState.mouseOver = mouseOver;

        if (mouseOver) {
          const c = generateRandomRgbaColor();
          squareState.fillColor = c;
          squareState.fillOpacity = 0.3;
        } else {
          squareState.fillOpacity = Math.max(0, squareState.fillOpacity - 0.01);
        }

        // Override with values from previous state if the mouse was already over the square
        if (previousState?.mouseOver) {
          squareState.fillColor = previousState.fillColor;
          squareState.fillOpacity = previousState.fillOpacity;
        }

        return squareState;
      });
      return newSquaresState;
    });
    setLastTick(currentTick);
  }, [currentTick, lastTick, layout, squares, mouseX, mouseY]);

  // Draw on canvas
  useLayoutEffect(() => {
    const w = layout.size.width;
    const h = layout.size.height;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let timeoutId: NodeJS.Timeout;
    let animationFrameId = 0;

    // Our render loop
    const render = () => {
      // Clear the canvas
      ctx.clearRect(0, 0, w, h);

      // Draw the squares
      // ctx.fillStyle = 'crimson';
      ctx.strokeStyle = '#9796A4';
      ctx.lineWidth = 1.5;

      squaresState.forEach(square => {
        // Use opacity
        ctx.strokeStyle = `rgba(56, 46, 40, ${square.strokeOpacity})`;
        ctx.beginPath();
        ctx.rect(square.x, square.y, w / layout.numberOfSquaresX, h / layout.numberOfSquaresY);
        ctx.stroke();

        // If mouse if inside the square, draw
        if (square.mouseOver) {
          const { fillOpacity, fillColor } = square;
          ctx.fillStyle = `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, ${fillOpacity})`;
          ctx.fillRect(square.x, square.y, w / layout.numberOfSquaresX, h / layout.numberOfSquaresY);

          // If square is even, draw a circle
          if (square.isEven) {
            ctx.beginPath();
            ctx.arc(
              square.x + w / layout.numberOfSquaresX / 2,
              square.y + h / layout.numberOfSquaresY / 2,
              20,
              0,
              2 * Math.PI,
            );
            ctx.stroke();
          }
          // Otherwise draw a small X inside of the square, do not touch the corners
          else {
            ctx.beginPath();
            ctx.moveTo(square.x + 20, square.y + 20);
            ctx.lineTo(square.x + w / layout.numberOfSquaresX - 20, square.y + h / layout.numberOfSquaresY - 20);
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(square.x + w / layout.numberOfSquaresX - 20, square.y + 20);
            ctx.lineTo(square.x + 20, square.y + h / layout.numberOfSquaresY - 20);
            ctx.stroke();
          }
        } else {
          const { fillOpacity, fillColor } = square;
          ctx.fillStyle = `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, ${fillOpacity})`;
          ctx.fillRect(square.x, square.y, w / layout.numberOfSquaresX, h / layout.numberOfSquaresY);
        }
      });

      // Request the next frame with a delay
      timeoutId = setTimeout(() => {
        // Request the next frame
        animationFrameId = requestAnimationFrame(render);

        // Update the tick
        setCurrentTick(previousTick => previousTick + 1);
      }, 40); // Introducing a delay of X ms
    };

    render();

    // Cleanup on unmount
    return () => {
      clearTimeout(timeoutId);
      cancelAnimationFrame(animationFrameId);
    };
  }, [layout, squaresState]);
  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleResize = () => {
      const { width, height } = container.getBoundingClientRect();

      // Resize the canvas if available
      const canvas = canvasRef.current;
      if (canvas) {
        canvas.width = width;
        canvas.height = height;
      }

      // Calculate number of squares
      const newNumberOfSquaresX = Math.ceil(width / MAX_SQUARE_SIZE);
      const newNumberOfSquaresY = Math.ceil(height / MAX_SQUARE_SIZE);

      // Update the layout state
      setLayout({
        size: { width, height },
        numberOfSquaresX: newNumberOfSquaresX,
        numberOfSquaresY: newNumberOfSquaresY,
      });
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef]);

  return (
    <Stack ref={containerRef} sx={{ flex: 1, position: 'relative', background: '#000' }}>
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
        }}
      />
    </Stack>
  );
};

export default React.memo(ParticlesEffect);
