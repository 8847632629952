import { CopyBold } from '../../components/Typography';

const LoadingPage = () => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CopyBold>Loading...</CopyBold>
    </div>
  );
};
export default LoadingPage;
