import axios from 'axios';
import { headerConfig } from './Firebase';
import { API_URL } from './config';
import { SystemCustomClaims } from './ifaces';

////////////////////////////////////////////////////////////////////////
////                            AUTH Functions                      ////
////////////////////////////////////////////////////////////////////////

type RedeemTokenResponse = {
  token: string;
};

export const redeemInviteToken = async (token: string): Promise<RedeemTokenResponse> => {
  const res = await axios.post(
    `${API_URL}/token`,
    {
      token: token,
    },
    await headerConfig(),
  );
  return res.data as RedeemTokenResponse;
};

export const redeemInviteCode = async (code: number): Promise<RedeemTokenResponse> => {
  const res = await axios.post(
    `${API_URL}/token`,
    {
      code: code,
    },
    await headerConfig(),
  );
  return res.data as RedeemTokenResponse;
};

////////////////////////////////////////////////////////////////////////
////                           ADMIN Functions                      ////
////////////////////////////////////////////////////////////////////////

export const getAllUsers = async (): Promise<SystemCustomClaims[]> => {
  const res = await axios.get(`${API_URL}/users`, await headerConfig()).catch(e => e.response);
  return res?.data as SystemCustomClaims[];
};

export const setUserPermissions = async (uid: string, permissions: string[]): Promise<void> => {
  await axios
    .post(`${API_URL}/set-permissions`, { uid: uid, permissions: permissions.join(',') }, await headerConfig())
    .catch(e => e.response);
};

////////////////////////////////////////////////////////////////////////
////                       TRIP PLANNER Functions                   ////
////////////////////////////////////////////////////////////////////////

export type AutoCompleteResponse = {
  predictions: AutoCompletePrediction[];
  status: 'OK' | 'REQUEST_DENIED';
};

export type AutoCompletePrediction = {
  description: string;
  place_id: string;
  reference: string;
  types: PlaceType[];
};

export type PlaceType = 'locality' | 'political' | 'geocode' | 'country';

export const autocompletePlace = async (input: string): Promise<AutoCompleteResponse> => {
  const response = await axios
    .post(`${API_URL}/ai-travel-assistant/autocomplete`, { input: input }, await headerConfig())
    .catch(e => e.response);

  return response.data as AutoCompleteResponse;
};

export const generateTripPlan = async (uid: string, permissions: string[]): Promise<void> => {
  await axios
    .post(`${API_URL}/planner/create`, { uid: uid, permissions: permissions.join(',') }, await headerConfig())
    .catch(e => e.response);
};
