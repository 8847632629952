import { getAuth, ParsedToken, User } from 'firebase/auth';
import React, { createContext } from 'react';
import { headerConfig } from './Firebase';

// --------------------------------------------------------
// Context to hold Authenticated user.

interface IAuthStateProps {
  user: User | null;
  isAdmin: boolean;
  customClaims: ParsedToken | null;
  loading: boolean;
  permissions: string[];
  roles: string[];
}

export const AuthCtx = createContext({} as IAuthStateProps);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // The user as it is, on firebase
  const [user, setUser] = React.useState<User | null>(null);
  const [loadingAuth, setLoadingAuth] = React.useState<boolean>(true);
  const [permissions, setPermissions] = React.useState<string[]>([]);
  const [roles, setRoles] = React.useState<string[]>([]);
  const [customClaims, setCustomClaims] = React.useState<ParsedToken | null>(null);
  const [isAdmin, setAdmin] = React.useState<boolean>(false);

  React.useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser && auth.currentUser) {
        const idTokenResult = await auth.currentUser.getIdTokenResult(true);
        const claims = idTokenResult.claims;
        setCustomClaims(claims);
        if (claims['permissions']) {
          const userPermissions = claims['permissions'] as string[];
          if (userPermissions.length > 0) {
            setPermissions(userPermissions);
          }
        }
        if (claims['roles']) {
          const customClaimPermissions = claims['roles'] as string[] | undefined;
          if (customClaimPermissions) {
            setRoles(customClaimPermissions);
          }
        }
        if (claims['isAdmin']) {
          const userIsAdmin = claims['isAdmin'] as string | undefined;
          if (userIsAdmin) {
            setAdmin(true);
          }
        }
        const config = await headerConfig();
        console.log(config);
      }
      setUser(firebaseUser);
      setLoadingAuth(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthCtx.Provider
      value={{
        user: user,
        isAdmin: isAdmin,
        loading: loadingAuth,
        customClaims: customClaims,
        permissions: permissions,
        roles: roles,
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};
