import { useContext } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import ScrollTo from 'react-scroll-into-view';

import { UICtx } from '../../../UIProvider';
import PulseDownButton from '../Banner/PulseDownButton';
import { Copy, Header2 } from '../../../components/Typography';
// Custom Components
import AvatarImage from './AvatarImage';

const ProfileSection = () => {
  const { isMobile, windowSize, darkMode } = useContext(UICtx);

  return (
    <Stack
      id={'profile-section'}
      sx={{
        pt: windowSize.width <= 900 ? '64px' : 0,
        background: darkMode ? '#111' : 'white',
        minHeight: '100svh',
        justifyContent: 'center',
        position: 'relative',
        transition: 'all 0.3s ease-in',
        // After
        ':after': {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          // background: 'url(https://grainy-gradients.vercel.app/noise.svg)',
          // opacity: 0.3,
          userSelect: 'none',
          // Filter, make darker
          filter: 'brightness(0.2)',
        },
      }}
    >
      <Grid container sx={{ flex: 1 }}>
        <Grid item xs={12} sm={12} md={6} component={Stack} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ minHeight: '300px', justifyContent: 'center' }}>
            <AvatarImage />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Stack}
          sx={{
            p: 4,
            alignItems: !isMobile ? 'center' : undefined,
            justifyContent: !isMobile ? 'center' : 'center',
          }}
        >
          <Stack>
            <Header2>About me</Header2>
            <Copy>I'm a software developer based in Berlin.</Copy>
          </Stack>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          height: '200px',
          position: isMobile ? 'relative' : 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          // background: darkMode
          //   ? 'linear-gradient(rgba(25,26,32,0), rgba(13,16,12,0.8))'
          //   : 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))',
          p: 3,
          transition: 'all 0.3s ease-in',
        }}
      >
        <ScrollTo selector={`#ai-travel-assistant-section`}>
          <PulseDownButton light={!darkMode} />
        </ScrollTo>
      </Box>
    </Stack>
  );
};

export default ProfileSection;
