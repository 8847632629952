export const FIREBASE_APP_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || '';
export const API_URL = process.env.REACT_APP_API_URL || '';
export const REACT_APP_PX_API_URL = process.env.REACT_APP_PX_API_URL || '';

export const firebaseConfig = {
  apiKey: FIREBASE_APP_API_KEY,
  authDomain: 'danielmontano-9802d.firebaseapp.com',
  databaseURL: 'https://danielmontano-9802d.firebaseio.com',
  projectId: 'danielmontano-9802d',
  storageBucket: 'danielmontano-9802d.appspot.com',
  messagingSenderId: '835898578138',
  appId: '1:835898578138:web:6b455527778afa1a67c1e3',
  measurementId: 'G-R2VWV6922B',
};
