import { Box, LinearProgress, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import FlightIcon from '@mui/icons-material/Flight';
import { Copy, Header1 } from '../../../components/Typography';
import GetStartedButton from './components/GetStartedButton';

interface AITravelAssistantIntroProps {
  onIntroComplete?: () => void;
  onGetStarted?: () => void;
}

const AITravelAssistantIntro: FC<AITravelAssistantIntroProps> = ({ onIntroComplete, onGetStarted }) => {
  const [progress, setProgress] = useState(0);
  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (progress === 100) {
        onIntroComplete?.();
      }
    }, 300);
  }, [progress, onIntroComplete]);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress(oldProgress => {
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 50);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      spacing={2}
    >
      {localLoading ? (
        <>
          <FlightIcon sx={{ width: '200px', height: '200px', color: '#DEDEDE' }} />
          <Box maxWidth={'300px'} sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progress} sx={{ color: 'blue' }} />
          </Box>
        </>
      ) : (
        <>
          <Header1
            sx={{
              textAlign: 'center',
            }}
          >
            AI Travel Assistant
          </Header1>
          <Copy sx={{ textAlign: 'center' }}>Use GPT-4 to generate a travel itinerary.</Copy>
          <GetStartedButton onClick={onGetStarted} />
        </>
      )}
    </Stack>
  );
};

export default AITravelAssistantIntro;
