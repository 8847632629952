import { Stack } from '@mui/material';
import { useContext } from 'react';
import { UICtx } from '../../UIProvider';

const UnauthorizedPage = () => {
  const { darkMode } = useContext(UICtx);
  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        color: darkMode ? 'white' : 'black',
        backgroundColor: darkMode ? 'black' : 'white',
      }}
    >
      Unauthorized
    </Stack>
  );
};

export default UnauthorizedPage;
