import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

// MUI
import { CircularProgress, InputBase, Stack, styled } from '@mui/material';
import { getAuth, GoogleAuthProvider, signInWithCustomToken, signInWithPopup } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';

// Contexts
import { AuthCtx } from '../../AuthProvider';

// CUSTOM
import GooglSignInDark from '../../assets/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png';
import GooglSignInLight from '../../assets/google_signin_buttons/web/2x/btn_google_signin_light_normal_web@2x.png';
import { UICtx } from '../../UIProvider';
import { redeemInviteCode } from '../../API';

interface WindowsLocationType {
  from: Location | undefined;
}

const isNumber = (str: string) => /^[0-9]*$/.test(str);

const SignInWithGoogleButton = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
  backgroundImage: `url(${GooglSignInDark})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: 192,
  height: 48,
});

interface SignInPageProps {
  allowSignInWithCode?: boolean;
}

interface CodeInputFieldProps {
  codeInputNumber: number;
  localInputState: string;
  localInputStateRef: React.RefObject<HTMLInputElement>;
  handleTextChange: (field_number: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleKeyPress: (field_number: number, event: React.KeyboardEvent<HTMLTextAreaElement | HTMLDivElement>) => void;
}

const CodeInputField: FC<CodeInputFieldProps> = ({
  codeInputNumber,
  localInputState,
  localInputStateRef,
  handleTextChange,
  handleKeyPress,
}) => {
  return (
    <InputBase
      onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        handleTextChange(codeInputNumber, event)
      }
      onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement | HTMLDivElement>) =>
        handleKeyPress(codeInputNumber, event)
      }
      value={localInputState}
      inputRef={localInputStateRef}
      inputProps={{ maxLength: 1 }}
      tabIndex={codeInputNumber}
      type="password"
      sx={{
        color: 'white',
        pr: 0,
        // m: 0,
        borderRadius: '8px',
        // https://www.w3docs.com/snippets/css/how-to-auto-hide-placeholder-text-on-focus-with-css-and-jquery.html
        // Trick to hide placeholder text when input is focused
        '.MuiInputBase-input:focus::placeholder': {
          color: 'transparent',
        },
        height: '80px',
        width: '80px',
        border: '2px solid white',
        fontSize: '30px',
        textAlign: 'center',
        // Style for the input element itself
        '& input': {
          textAlign: 'center',
          fontSize: '30px',
          fontWeight: 500,
        },
      }}
    />
  );
};

const SignInPage: FC<SignInPageProps> = ({ allowSignInWithCode }) => {
  // HOOKS
  const location = useLocation();
  const navigate = useNavigate();

  // CONTEXTS
  const { user } = React.useContext(AuthCtx);
  const { darkMode } = React.useContext(UICtx);

  // STATE
  const [signInCode, setSignInCode] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const localInputState1Ref = useRef<HTMLInputElement>(null);
  const localInputState2Ref = useRef<HTMLInputElement>(null);
  const localInputState3Ref = useRef<HTMLInputElement>(null);
  const localInputState4Ref = useRef<HTMLInputElement>(null);

  const [localInputState1, setLocalInputState1] = useState('');
  const [localInputState2, setLocalInputState2] = useState('');
  const [localInputState3, setLocalInputState3] = useState('');
  const [localInputState4, setLocalInputState4] = useState('');

  const state = location.state as WindowsLocationType | undefined;
  const from = state?.from?.pathname || '/';

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [from, navigate, user]);

  const handleSignInWithGoogle = async () => {
    try {
      // Sign in using firebase google auth provider
      setLoading(true);
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleSignInWithInviteCode = async () => {
      if (!signInCode) {
        return;
      }
      try {
        setLoading(true);
        const customTokenResponse = await redeemInviteCode(parseInt(signInCode));
        const auth = getAuth();
        await signInWithCustomToken(auth, customTokenResponse.token);
      } catch (error) {
        setLoading(false);
      }
    };

    if (signInCode.length === 4) {
      handleSignInWithInviteCode();
    }
  }, [signInCode]);

  useEffect(() => {
    if (
      localInputState1.length !== 1 ||
      localInputState2.length !== 1 ||
      localInputState3.length !== 1 ||
      localInputState4.length !== 1
    ) {
      setSignInCode('');
      return;
    }
    const code = localInputState1 + localInputState2 + localInputState3 + localInputState4;
    setSignInCode(code);
  }, [localInputState1, localInputState2, localInputState3, localInputState4]);

  const handleKeyPress = (field_number: number, event: React.KeyboardEvent<HTMLTextAreaElement | HTMLDivElement>) => {
    // Check is pressed key is a number
    const newVal = event.key;
    const newValIsNumber = isNumber(newVal);
    if (newValIsNumber) {
      if (field_number === 1) {
        setLocalInputState1(newVal);
        // Set Focus to next input
        if (localInputState2Ref.current && newVal.length === 1) {
          localInputState2Ref.current.focus();
        }
      }
      if (field_number === 2) {
        setLocalInputState2(newVal);
        if (localInputState3Ref.current && newVal.length === 1) {
          localInputState3Ref.current.focus();
        }
        if (localInputState1Ref.current && newVal.length === 0) {
          localInputState1Ref.current.focus();
        }
      }
      if (field_number === 3) {
        setLocalInputState3(newVal);
        if (localInputState4Ref.current && newVal.length === 1) {
          localInputState4Ref.current.focus();
        }
        if (localInputState2Ref.current && newVal.length === 0) {
          localInputState2Ref.current.focus();
        }
      }
      if (field_number === 4) {
        setLocalInputState4(newVal);
        if (localInputState3Ref.current && newVal.length === 0) {
          localInputState3Ref.current.focus();
        }
      }
      event.preventDefault();
      return;
    } else if (event.key === 'Backspace' || event.key === 'Delete') {
      if (field_number === 1) {
        setLocalInputState1('');
      }
      if (field_number === 2) {
        if (localInputState2.length === 0 && localInputState1Ref.current) {
          localInputState1Ref.current.focus();
        }
        setLocalInputState2('');
      }
      if (field_number === 3) {
        if (localInputState3.length === 0 && localInputState2Ref.current) {
          localInputState2Ref.current.focus();
        }
        setLocalInputState3('');
      }
      if (field_number === 4) {
        if (localInputState4.length === 0 && localInputState3Ref.current) {
          localInputState3Ref.current.focus();
        }
        setLocalInputState4('');
      }
      event.preventDefault();
      return;
    }
  };
  const handleTextChange = (field_number: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newVal = event.target.value;
    console.log(newVal);
    const newValIsNumber = isNumber(newVal);
    if (!newValIsNumber) {
      return;
    }
    if (field_number === 1) {
      setLocalInputState1(newVal);
      // Set Focus to next input
      if (localInputState2Ref.current && newVal.length === 1) {
        localInputState2Ref.current.focus();
      }
    }
    if (field_number === 2) {
      setLocalInputState2(newVal);
      if (localInputState3Ref.current && newVal.length === 1) {
        localInputState3Ref.current.focus();
      }
      if (localInputState1Ref.current && newVal.length === 0) {
        localInputState1Ref.current.focus();
      }
    }
    if (field_number === 3) {
      setLocalInputState3(newVal);
      if (localInputState4Ref.current && newVal.length === 1) {
        localInputState4Ref.current.focus();
      }
      if (localInputState2Ref.current && newVal.length === 0) {
        localInputState2Ref.current.focus();
      }
    }
    if (field_number === 4) {
      setLocalInputState4(newVal);
      if (localInputState3Ref.current && newVal.length === 0) {
        localInputState3Ref.current.focus();
      }
    }
  };

  return (
    <Stack
      sx={{
        flex: 1,
        backgroundSize: 'cover',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: darkMode ? 'black' : 'white',
      }}
    >
      {isLoading ? (
        <CircularProgress sx={{ color: 'white' }} />
      ) : allowSignInWithCode ? (
        <Stack direction={'row'} spacing={2}>
          <CodeInputField
            codeInputNumber={1}
            localInputState={localInputState1}
            localInputStateRef={localInputState1Ref}
            handleTextChange={handleTextChange}
            handleKeyPress={handleKeyPress}
          />
          <CodeInputField
            codeInputNumber={2}
            localInputState={localInputState2}
            localInputStateRef={localInputState2Ref}
            handleTextChange={handleTextChange}
            handleKeyPress={handleKeyPress}
          />
          <CodeInputField
            codeInputNumber={3}
            localInputState={localInputState3}
            localInputStateRef={localInputState3Ref}
            handleTextChange={handleTextChange}
            handleKeyPress={handleKeyPress}
          />
          <CodeInputField
            codeInputNumber={4}
            localInputState={localInputState4}
            localInputStateRef={localInputState4Ref}
            handleTextChange={handleTextChange}
            handleKeyPress={handleKeyPress}
          />
        </Stack>
      ) : (
        <Stack
          spacing={2}
          minWidth={'400px'}
          sx={{ background: darkMode ? 'black' : 'white', borderRadius: '15px', padding: 10, alignItems: 'center' }}
        >
          <SignInWithGoogleButton
            onClick={() => handleSignInWithGoogle()}
            sx={{
              backgroundImage: `url(${darkMode ? GooglSignInDark : GooglSignInLight})`,
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default SignInPage;
