import { IconButton, IconButtonProps } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { FC } from 'react';

const StartButton: FC<IconButtonProps> = props => {
  return (
    <IconButton {...props}>
      <PlayArrowIcon sx={{ color: '#5F5F5F' }} />
    </IconButton>
  );
};

export default StartButton;
