import { FC, useContext, useState } from 'react';
import { Stack } from '@mui/material';
import { getAuth, signInAnonymously } from 'firebase/auth';
import ChatInputField from './InputField';
import { Copy, Header1 } from '../../../components/Typography';
import AITravelAssistantIntro from './AITravelAssistantIntro';
import AITravelAssistantResult from './AITravelAssistantResult';
import NavigationBar from './components/NavigationBar';
import { AuthCtx } from '../../../AuthProvider';

export enum AITravelAssistantState {
  INTRO = 0,
  GET_DESTINATION = 1,
  GENERATE_ITINERARY = 2,
  DISPLAY_RESULT = 3,
  // ERROR = -1,
}

interface AITravelAssistantProps {
  onRequestUserToSignInAnonymously?: () => void;
}

const AITravelAssistant: FC<AITravelAssistantProps> = ({ onRequestUserToSignInAnonymously }) => {
  const { user } = useContext(AuthCtx);

  const [assistantState, setAssistantState] = useState<AITravelAssistantState | undefined>(
    AITravelAssistantState.INTRO,
  );

  const [destination, setDestination] = useState<string>();

  const handleGetStarted = async () => {
    if (user) {
      setAssistantState(AITravelAssistantState.GET_DESTINATION);
    } else {
      try {
        const auth = getAuth();
        await signInAnonymously(auth);
        setAssistantState(AITravelAssistantState.GET_DESTINATION);
      } catch (error) {
        console.log(error);
      }
      // onRequestUserToSignInAnonymously?.();
    }
  };

  if (assistantState === AITravelAssistantState.INTRO) {
    return (
      <AITravelAssistantIntro
        onIntroComplete={() => setAssistantState(AITravelAssistantState.GET_DESTINATION)}
        onGetStarted={handleGetStarted}
      />
    );
  }

  const generateItinerary = async () => {
    setAssistantState(AITravelAssistantState.GENERATE_ITINERARY);

    setTimeout(() => {
      setAssistantState(AITravelAssistantState.DISPLAY_RESULT);
    }, 2000);
  };

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      spacing={1}
    >
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           GET DESTINATION                      //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {assistantState === AITravelAssistantState.GET_DESTINATION && (
        <>
          <Header1
            sx={{
              textAlign: 'center',
            }}
          >
            AI Travel Assistant
          </Header1>
          <Copy sx={{ textAlign: 'center' }}>Use GPT-4 to generate a travel itinerary.</Copy>
          <ChatInputField
            onInputText={input => {
              setDestination(input);
              generateItinerary();
            }}
          />
        </>
      )}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                          GENERATE ITINERARY                    //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {assistantState === AITravelAssistantState.GENERATE_ITINERARY && (
        <>
          <Copy sx={{ textAlign: 'center' }}>Generating Itinerary for {destination}</Copy>
        </>
      )}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           SHOWING ITINERARY                    //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {assistantState === AITravelAssistantState.DISPLAY_RESULT && (
        <>
          <NavigationBar onHomeButtonClick={() => setAssistantState(AITravelAssistantState.INTRO)} />
          <AITravelAssistantResult />
        </>
      )}
    </Stack>
  );
};

export default AITravelAssistant;
