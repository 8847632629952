import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, InputBase, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { UICtx } from '../../../UIProvider';
import { AutoCompletePrediction, autocompletePlace } from '../../../API';

interface ChatInputFieldProps {
  inputText?: string;
  onInputText?: (message: string) => void;
  onVerifyMessage?: () => void;
}

const ChatInputField: FC<ChatInputFieldProps> = ({ inputText, onInputText }) => {
  const { darkMode, isMobile } = useContext(UICtx);
  const [localInputState, setLocalInputState] = useState('');

  const [lastInputTime, setLastInputTime] = useState(0);
  const [lastInputTimeout, setLastInputTimeout] = useState<NodeJS.Timeout | null>(null);

  const [predictions, setPredictions] = useState<AutoCompletePrediction[]>([]);
  const [predictionsOpen, setPredictionsOpen] = useState(false);
  const [selectedPrediction, setSelectedPrediction] = useState<AutoCompletePrediction | null>(null);

  const fetchPlaces = async (input: string) => {
    if (input.length < 3) {
      setPredictions([]);
      return;
    }
    const result = await autocompletePlace(input);
    setPredictions(result.predictions);
    setPredictionsOpen(true);
  };

  const handleSendMessage = () => {
    if (onInputText && localInputState !== '') {
      onInputText(localInputState);
      setLocalInputState('');
    }
  };

  // Handle enter key press
  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onInputText) {
      handleSendMessage();
    }
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newVal = event.target.value;
    setLocalInputState(newVal);

    // Check if last input was less than 500ms ago
    if (Date.now() - lastInputTime < 500) {
      // Clear timeout
      if (lastInputTimeout) {
        clearTimeout(lastInputTimeout);
      }
    }
    // Set new timeout
    const newTimeout = setTimeout(async () => {
      await fetchPlaces(newVal);
    }, 500);

    setLastInputTimeout(newTimeout);

    // Set last input time
    setLastInputTime(Date.now());
  };

  useEffect(() => {
    if (inputText) {
      setLocalInputState(inputText);
    }
  }, [inputText]);

  return (
    <Box
      sx={{
        display: 'flex',
        background: darkMode ? 'black' : 'rgb(244, 245, 251)',
        borderRadius: '40px',
        p: 1,
        pr: 1,
        minWidth: '300px',
        maxWidth: '500px',
        position: 'relative',
      }}
    >
      <InputBase
        onChange={handleTextChange}
        // Listen for enter key press
        onKeyPress={handleKeyPress}
        value={localInputState}
        placeholder="Enter your destination"
        sx={{
          flex: 1,
          color: darkMode ? '#CCC' : 'black',
          pl: 1,
          pr: 0,
          pb: '4px',
          pt: '4px',
          fontSize: isMobile ? '1rem' : '1.5rem',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          '.MuiInputBase-input::placeholder': {
            fontSize: '1rem',
          },
          // https://www.w3docs.com/snippets/css/how-to-auto-hide-placeholder-text-on-focus-with-css-and-jquery.html
          // Trick to hide placeholder text when input is focused
          '.MuiInputBase-input:focus::placeholder': {
            color: 'transparent',
            fontSize: '1rem',
          },
          borderBottom: darkMode ? '2px solid white' : '2px solid black',
          position: 'relative',
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleSendMessage}
              sx={{
                color: '#B1B1A1',
              }}
              disableRipple
            >
              <SendIcon
                sx={{
                  fontSize: '1.1rem',
                }}
              />
            </IconButton>
          </InputAdornment>
        }
      />
      {predictionsOpen && (
        <Stack
          sx={{
            top: '100%',
            right: 0,
            left: 0,
            position: 'absolute',
          }}
        >
          {predictions.map(prediction => {
            return (
              <Box
                sx={{
                  background: '#fff',
                  zIndex: 100,
                  borderRadius: '10px',
                  p: 1,
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                <Stack
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      background: '#eee',
                    },
                  }}
                  onClick={() => {
                    setSelectedPrediction(prediction);
                    setLocalInputState(prediction.description);
                    setPredictionsOpen(false);
                  }}
                >
                  {prediction.description}
                </Stack>
              </Box>
            );
          })}
        </Stack>
      )}
    </Box>
  );
};
export default ChatInputField;
