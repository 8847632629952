import React, { createContext, useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useCookies } from 'react-cookie';

// --------------------------------------------------------

// Context to hold Notifications Data.

type WindowSize = {
  width: number;
  height: number;
};

interface UICtxProps {
  isMobile: boolean;
  windowSize: WindowSize;
  darkMode: boolean;
  setDarkMode: (value: boolean) => void;
}

export const UICtx = createContext({} as UICtxProps);

export const UIProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true);
  const { height, width } = useWindowSize();
  const [cookies, setCookie] = useCookies(['functional_cookies_accepted', 'dark_mode']);
  const isMobile = width !== undefined ? width < 768 : false;

  // Check if browser supports dark mode
  useEffect(() => {
    if (cookies.dark_mode !== undefined) {
      // Check if dark mode is enabled, as user cookie
      const darkModeEnabled = cookies.dark_mode === 'true' || cookies.dark_mode === true;
      setDarkMode(darkModeEnabled);
    } else {
      // If not then check system defaults
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setDarkMode(prefersDarkMode);
    }
  }, [cookies]);

  const handleSetDarkMode = (value: boolean) => {
    const functionalCookiesEnabled = cookies.functional_cookies_accepted === 'true';
    if (functionalCookiesEnabled) {
      document.cookie = `dark_mode=${value}; path=/; max-age=31536000`;
    }
    // Update cookie
    setCookie('dark_mode', value, { path: '/', maxAge: 31536000 });
    setDarkMode(value);
  };

  return (
    <UICtx.Provider
      value={{
        windowSize: { height, width },
        isMobile: isMobile,
        darkMode: darkMode,
        setDarkMode: handleSetDarkMode,
      }}
    >
      {children}
    </UICtx.Provider>
  );
};
