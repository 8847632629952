// Custom Components
import { useContext, useEffect } from 'react';
import { Stack } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import MainLayout from '../MainLayout';
import { Header3 } from '../../components/Typography';
import { AuthCtx } from '../../AuthProvider';

const SignOutPage = () => {
  const { user } = useContext(AuthCtx);

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  useEffect(() => {
    setTimeout(
      () => {
        handleSignOut();
        window.location.href = '/';
      },
      user ? 100 : 2000,
    );
  }, [user]);

  return (
    <MainLayout>
      <Stack
        sx={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {user && <Header3 sx={{ color: 'white' }}>Bye bye ✌️</Header3>}
      </Stack>
    </MainLayout>
  );
};
export default SignOutPage;
