import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';

// MUI
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import SecureLayout from './pages/SecureLayout';
// FIREBASE
// Importing the firebase module will initialize the firebase app client
import './Firebase';

// Provider
import { AuthProvider } from './AuthProvider';
import { DataProvider } from './DataProvider';

// Pages
import SignInPage from './pages/SignInPage/SignInPage';
import Page404 from './pages/404Page/Page404';
import theme from './theme';
import { UIProvider } from './UIProvider';

import reportWebVitals from './reportWebVitals';
import LandingPage from './pages/LandingPage/LandingPage';
import CVPage from './pages/CVPage/CVPage';
import TravelAssistantPage from './pages/Projects/TripPlanner/TripPlannerPage';
import AdminPage from './pages/Admin/Admin';
import SignOutPage from './pages/SignOutPage/SignOutPage';
import DevPage from './pages/DevPage/DevPage';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <AuthProvider>
        <DataProvider>
          <UIProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                               SignIn                           //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route path="/login" element={<SignInPage />} />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                               SignOut                          //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route path="/signout" element={<SignOutPage />} />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                            Token Sign In                       //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route path="/auth" element={<SignInPage allowSignInWithCode />} />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                              CV Page                           //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  {/* <Route
                    path="/cv"
                    element={
                      <SecureLayout requiresToken permissionRequired permissionName="CV">
                        <CVPage />
                      </SecureLayout>
                    }
                  /> */}

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                             Trip Planner                       //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route
                    path="/travel-assistant"
                    element={
                      <SecureLayout>
                        <TravelAssistantPage />
                      </SecureLayout>
                    }
                  />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                                 DEV                            //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  {process.env.REACT_APP_DEBUG === 'true' && <Route path="/dev" element={<DevPage />} />}

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                                ADMIN                           //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route
                    path="/admin"
                    element={
                      <SecureLayout adminRequired>
                        <AdminPage />
                      </SecureLayout>
                    }
                  />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                          NOT FOUND PAGE                        //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route path="*" element={<Page404 />} />
                </Routes>
              </BrowserRouter>
            </ThemeProvider>
          </UIProvider>
        </DataProvider>
      </AuthProvider>
    </CookiesProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
