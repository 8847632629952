import { IconButton, IconButtonProps } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import { FC } from 'react';

const PauseButton: FC<IconButtonProps> = props => {
  return (
    <IconButton {...props}>
      <PauseIcon sx={{ color: '#5F5F5F' }} />
    </IconButton>
  );
};

export default PauseButton;
