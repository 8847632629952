import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import ScrollTo from 'react-scroll-into-view';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TypeAnimation } from 'react-type-animation';
import { UICtx } from '../../../UIProvider';
import StartButton from './StartButton';
import PauseButton from './PauseButton';
import ParticlesEffect from './ParticlesEffect';
import PulseDownButton from './PulseDownButton';
import useStickyScroll from '../../../hooks/useStickyScroll';

interface BannerProps {
  onScrollAway?: (isAway: boolean) => void;
}

const Banner: FC<BannerProps> = ({ onScrollAway }) => {
  // Banner's Container Ref
  const containerRef = useRef<HTMLDivElement>(null);

  // Ref to keep track of the banner container section
  const { offset, objectHeight } = useStickyScroll(0, containerRef.current);

  const youtubeVideoId = '4kLviL8XwAI';
  const [youtubeVideoUrl, setYoutubeVideoUrl] = useState<string>();
  const [play, setPlay] = useState(false);

  const { isMobile } = useContext(UICtx);

  const [openCopyTooltip, setOpenCopyTooltip] = useState(false);
  const [animationDidEnd, setAnimationDidEnd] = useState(false);

  const handleCloseTooltip = () => {
    setOpenCopyTooltip(false);
  };

  const handleOpenTooltip = () => {
    // Copy to clipboard
    navigator.clipboard.writeText('hello@danielmontano.io');
    setOpenCopyTooltip(true);
  };

  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const url = play
      ? `https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1`
      : `https://www.youtube.com/embed/${youtubeVideoId}`;
    setYoutubeVideoUrl(url);
  }, [play, youtubeVideoId]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleMouseMove = (e: MouseEvent) => {
      // Your mouse move logic here...
      // Take window scroll into account if necessary

      const rect = window.document.body.getBoundingClientRect();

      setMousePosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [containerRef]);

  useEffect(() => {
    setTimeout(() => {
      setAnimationDidEnd(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!onScrollAway) return;

    if (offset > objectHeight - 64) {
      onScrollAway(true);
    } else {
      onScrollAway(false);
    }
  }, [onScrollAway, offset, objectHeight]);

  return (
    <Stack
      ref={containerRef}
      sx={{
        p: 4,
        flex: 1,
        minHeight: '100svh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // p: isMobile ? 20 : 4,
        position: 'relative',
        // background: 'salmon',
        // background: 'linear-gradient(to right, #FDFBFB, #EBEDEE 70%)',
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        background: 'linear-gradient(230deg,#a24bcf,#4b79cf,#4bc5cf,#FE6B8B,#FF8E53)',
        backgroundSize: '300% 300%',
        animation: 'MoveBG 30s ease infinite',
        '@keyframes MoveBG': {
          '0%': { backgroundPosition: '0% 50%' },
          '50%': { backgroundPosition: '100% 50%' },
          '100%': { backgroundPosition: '0% 50%' },
        },
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      }}
      // spacing={4}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          zIndex: -5,
          // Radial gradient
          background: 'radial-gradient(rgba(25,26,32,0.5),rgba(10,12,13))',
          // After
          ':after': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            background: 'url(https://grainy-gradients.vercel.app/noise.svg)',
            opacity: 0.3,
            userSelect: 'none',
            // Filter, make darker
            filter: 'brightness(0.2)',
          },
        }}
      >
        <ParticlesEffect mouseX={mousePosition.x} mouseY={mousePosition.y} />
      </Stack>
      {!isMobile && (
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            'Hi',
            500, // wait 1s before replacing "Mice" with "Hamsters"
            "Hi, I'm Daniel",
            1000,
          ]}
          wrapper="span"
          speed={50}
          style={{
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            // textTransform: 'uppercase',
            fontSize: '8rem',
            lineHeight: '8rem',
            fontWeight: 'bold',
            color: '#1F1F1D',
            textAlign: 'center',
            userSelect: 'none',
          }}
        />
      )}

      {isMobile && (
        <Typography
          sx={{
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            // textTransform: 'uppercase',
            fontSize: '6rem',
            lineHeight: '6rem',
            fontWeight: 'bold',
            color: '#1F1F1D',
            textAlign: 'left',
            mx: isMobile ? 3 : 8,
            letterSpacing: '-0.2rem',
            background: 'linear-gradient(230deg,#a24bcf,#4b79cf,#4bc5cf,#FE6B8B,#FF8E53)',
            backgroundSize: '300% 300%',
            animation: 'MoveBG 30s ease infinite',
            userSelect: 'none',
            '@keyframes MoveBG': {
              '0%': { backgroundPosition: '0% 50%' },
              '50%': { backgroundPosition: '100% 50%' },
              '100%': { backgroundPosition: '0% 50%' },
            },
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
          }}
        >
          Hi, I'm <br />
          Daniel
        </Typography>
      )}

      {isMobile && (
        <Link
          href="mailto:hello@danielmontano.io?subject=Hi 👋"
          sx={{
            textDecoration: 'none',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: '#1F1F1D',
            textAlign: 'left',
            mx: isMobile ? 0 : 8,
            // minWidth: width >= 500 ? '350px' : undefined,
            background: 'linear-gradient(230deg,#a24bcf,#4b79cf,#4bc5cf,#FE6B8B,#FF8E53)',
            backgroundSize: '300% 300%',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            border: '2px solid #FFF',
            borderImage: 'linear-gradient(230deg,#a24bcf,#4b79cf,#4bc5cf) 1',
            borderImageSlice: 1,
            userSelect: 'none',
            animation: 'MoveBG 30s ease infinite',
            '@keyframes MoveBG': {
              '0%': { backgroundPosition: '0% 50%' },
              '50%': { backgroundPosition: '100% 50%' },
              '100%': { backgroundPosition: '0% 50%' },
            },
            px: isMobile ? 2 : 3,
            py: 1,
            borderRadius: '20px',
            mt: 4,
          }}
        >
          Get in touch
        </Link>
      )}

      {!isMobile && [
        <Typography
          sx={{
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: '#1F1F1D',
            textAlign: 'left',
            background: 'linear-gradient(230deg,#a24bcf,#4b79cf,#4bc5cf,#FE6B8B,#FF8E53)',
            backgroundSize: '300% 300%',
            animation: 'MoveBG 30s ease infinite',
            userSelect: 'none',
            '@keyframes MoveBG': {
              '0%': { backgroundPosition: '0% 50%' },
              '50%': { backgroundPosition: '100% 50%' },
              '100%': { backgroundPosition: '0% 50%' },
            },
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            mt: 4,
            opacity: animationDidEnd ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
        >
          Get in touch
        </Typography>,
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            mx: 8,
            opacity: animationDidEnd ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
        >
          <Link
            href="mailto:hello@danielmontano.io?subject=Hi 👋&body=Hi Daniel,"
            sx={{
              textDecoration: 'none',
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontSize: '1.5rem',
              textAlign: 'left',

              background: 'white',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
              color: '#1F1F1D',
            }}
          >
            hello@danielmontano.io
          </Link>
          <Tooltip open={openCopyTooltip} onClose={handleCloseTooltip} title="Copied">
            <IconButton sx={{ pt: '10px' }} onClick={handleOpenTooltip}>
              <ContentCopyIcon sx={{ color: 'white', fontSize: '1.2rem' }} />
            </IconButton>
          </Tooltip>
        </Stack>,
      ]}

      {play && youtubeVideoUrl && (
        <iframe
          width="0"
          height="0"
          src={youtubeVideoUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          style={{ display: 'none' }}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          position: 'absolute',
          bottom: '0',
          right: '0',
          left: '0',
          height: '200px',
          // Background Gradient vertical 0 opacity black to 100% opacity black
          background: 'linear-gradient(rgba(20,21,21,0), rgba(10,12,11,0.8))',
          p: 3,
        }}
      >
        <ScrollTo selector={`#profile-section`}>
          <PulseDownButton />
        </ScrollTo>
      </Box>

      {!isMobile && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '0',
            right: '0',
            height: '50px',
            width: '50px',
            // margin: '1rem',
          }}
        >
          {play ? (
            <PauseButton
              onClick={() => setPlay(!play)}
              sx={{
                color: '#5F5F5F',
              }}
            />
          ) : (
            <StartButton
              onClick={() => setPlay(!play)}
              sx={{
                color: '#5F5F5F',
              }}
            />
          )}
        </Box>
      )}
    </Stack>
  );
};
export default Banner;
