import { useContext } from 'react';
import { Stack } from '@mui/material';
import { Copy } from '../../../components/Typography';
import { UICtx } from '../../../UIProvider';

const Footer = () => {
  const { darkMode } = useContext(UICtx);
  return (
    <Stack sx={{ p: 2, justifyContent: 'center', alignItems: 'center', background: 'rgba(5,6,5)' }}>
      <Copy sx={{ color: darkMode ? '#666' : 'white', fontSize: '0.7rem' }}>© 2023 Daniel Montano</Copy>
    </Stack>
  );
};

export default Footer;
