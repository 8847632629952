// MUI
import { Stack } from '@mui/material';

// Custom Components
import { FC } from 'react';
import { Copy } from '../../../../components/Typography';

interface NavigationBarProps {
  onHomeButtonClick?: () => void;
}

const NavigationBar: FC<NavigationBarProps> = ({ onHomeButtonClick }) => {
  return (
    <Stack
      sx={{
        height: '64px',
        px: 2,
        alignItems: 'center',
        py: 1,
        top: 0,
        zIndex: 10,
        width: '100%',
      }}
    >
      <Stack
        maxWidth={'lg'}
        flexDirection={'row'}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Stack
          sx={{
            width: '24px',
            height: '24px',
            cursor: 'pointer',
          }}
          onClick={onHomeButtonClick}
        >
          <Copy>Home</Copy>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NavigationBar;
