import { useState } from 'react';
import { getAuth, signInAnonymously } from 'firebase/auth';
import MainLayout from '../MainLayout';
import Footer from './Footer/LandingPageFooter';
import Banner from './Banner/Banner';
// import CoolStorySection from './CoolStorySection/CoolStorySection';
import TripPlannerSection from './TripPlannerSection/TripPlannerSection';
import ProfileSection from './ProfileSection/ProfileSection';
import FixedNavigationBar from '../../components/FixedNavigationBar';

const LandingPage = () => {
  const [isBelowBanner, setIsBelowBanner] = useState<boolean>(false);

  const handleSignInAnonymously = async () => {
    const auth = getAuth();
    signInAnonymously(auth);
  };

  return (
    <MainLayout>
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                             Navigation                         //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <FixedNavigationBar lightVersion={isBelowBanner} isBelowBanner={isBelowBanner} />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                               Banner                           //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <Banner onScrollAway={setIsBelowBanner} />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           Profile Section                      //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <ProfileSection />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                            Trip Planner                        //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {/* <TripPlannerSection /> */}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                             CoolStory                          //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {/* <CoolStorySection /> */}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                               Footer                           //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <Footer />
    </MainLayout>
  );
};
export default LandingPage;
