import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
// --------------------------------------------------------

interface DataProps {
  isLoading: boolean;
  setLoading: (state: boolean) => void;
  fetchData: () => void;

  functionalCookiesAccepted: boolean;
}

export const DataCtx = createContext({} as DataProps);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(async function () {
    setLoading(false);
  }, []);

  const [cookies] = useCookies(['functional_cookies_accepted']);
  const [functionalCookiesAccepted, setFunctionalCookiesAccepted] = useState(false);

  useEffect(() => {
    if (cookies.functional_cookies_accepted === 'true' || cookies.functional_cookies_accepted === true) {
      setFunctionalCookiesAccepted(true);
    } else {
      setFunctionalCookiesAccepted(false);
    }
  }, [cookies.functional_cookies_accepted]); // Re-run effect if the cookie value changes

  return (
    <DataCtx.Provider
      value={{
        isLoading,
        setLoading,
        fetchData: fetchData,
        functionalCookiesAccepted: functionalCookiesAccepted,
      }}
    >
      {children}
    </DataCtx.Provider>
  );
};
