import mapboxgl, { Map } from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { Copy } from '../../../components/Typography';
import NavigationBar from './components/NavigationBar';

mapboxgl.accessToken =
  'pk.eyJ1Ijoid2VhcnlzdW5sYW1wIiwiYSI6ImNsbTdrMWszcTAxZmUzam81ajN0ZWY2YXYifQ.21Y99g0G1Nj2r8sL9xw2Eg';

const AITravelAssistantResult = () => {
  const [results, setResults] = useState<string[]>();

  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<Map>();
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);

  useEffect(() => {
    if (!mapContainer.current || map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom,
    });
    if (!map.current) return;
    const mapCurrent = map.current;
    map.current.on('move', () => {
      setLng(parseFloat(mapCurrent.getCenter().lng.toFixed(4)));
      setLat(parseFloat(mapCurrent.getCenter().lat.toFixed(4)));
      setZoom(parseFloat(mapCurrent.getZoom().toFixed(2)));
    });
  });

  return (
    <Stack direction={'row'} sx={{ flex: 1, width: '100%' }}>
      <Stack sx={{ flex: 1, backgroundColor: 'turquoise' }}>
        <Copy sx={{ textAlign: 'center' }}>Showing Itinerary</Copy>
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <div
          ref={mapContainer}
          className="map-container"
          style={{
            flex: 1,
            backgroundColor: 'salmon',
            position: 'relative',
          }}
        />
      </Stack>
    </Stack>
  );
};

export default AITravelAssistantResult;
